/* Setting the blur effect */
.pathfindingVisualizer {
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

/* Css for the Popup */
.PopUp {
    top: 25vh;
    left: 20%;
    position: absolute;
    width: 75%;
    height: 70vh;
}

.PopUp .PopUpTextBox {
    width: 80%;
    height: 50vh;
    background-color: white;
    z-index: 10;

    border: 2px solid black;
    border-radius: 20px;
}

.PopUp .PopUpTextBox header {
    box-sizing: border-box;
    border: 2px solid #27ae60;

    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 6px 5px 0 5px;
    display: flex;
    justify-content: space-between;

    width: 100%;

    height: 10%;

    background-color: #27ae60;
    color: white;
}

.PopUp .PopUpTextBox header h3 {
    padding-right: 5px;
    cursor: pointer;
}

.PopUp .PopUpTextBox header h3:hover {
    color: #111;
}

.PopUp .PopUpTextBox .PopUpIntroductionText{
    width: 100%;
    height: 80%;
}

.PopUp .PopUpTextBox .PopUpIntroductionText .player{
    display: flex;
    flex-wrap:wrap;
    width: 100%;
    text-align: left;
    padding:10px;
}

.player h3{
    margin: 10px;
}

.player img,
.player div{
    width:80px;
    height:50px;
    margin:30px;
}

.PopUp .PopUpTextBox .skipBox{
    box-sizing: border-box;

    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 10%;
}

.PopUp .PopUpTextBox .skipBox small span {
    color: #284baa;
}


.PopUp .PopUpTextBox .skipBox button{
    color : white;
    margin-right: 10px;
    width: 10%;
    background-color : #48c9b0;
    border : 1px solid #48c9b0;
    border-radius : 3px;

    transition : 0.8s;
}

.PopUp .PopUpTextBox .skipBox button:hover{
    color : #111;
    cursor: pointer;
}

@media(max-width:780px) {
    .PopUp {
        top: 0;
        left: 0;
        position: absolute;
        width: 200%;
        height: 200vh;
    }
    .PopUp .PopUpTextBox {
        width: 100%;
        height: 80vh;
        border: none;
    }
    .player img, .player div {
        width: 60px;
        height: 50px;
    }
    .PopUp .PopUpTextBox header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
}

