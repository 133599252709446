.node
{
    width: 30px;
    height: 22px;

    border: 1px solid rgb(175, 216, 248);
    /* Using inline block as we are storing every row in a separate div */
}

.node-start
{
    background-color: rgb(233, 30, 30);
    background-image: url(images/start.png);
    background-position: center;
    background-size: cover;
}

.node-finish
{
    background-color: rgb(141, 243, 82);
    background-image: url(images/end.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.node-wall
{
    border: 1px solid #34495e;
    background-color: #34495e;
}
.node-weight
{
    background-image: url(images/weight.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.node-visited
{
    animation-name: visitedAnimation;
    animation-duration: 1.5s;
    animation-play-state: running;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-iteration-count: 1;
    animation-direction: alternate;

    animation-fill-mode: forwards;
}
@keyframes visitedAnimation
{
    0%
    {
        background-color: rgba(0, 0, 66, .75);
    }

    50%
    {
        background-color: rgba(17, 104, 217, .75);
    }

    75%
    {
        transform: scale(1.2);

        background-color: rgba(0, 217, 159, .75);
    }

    100%
    {
        transform: scale(1);

        background-color: rgba(0, 190, 218, .75);
    }
}

.node-visitedWeight
{
    animation-name: visitedWeightAnimation;
    animation-duration: 1.5s;
    animation-play-state: running;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-iteration-count: 1;
    animation-direction: alternate;

    animation-fill-mode: forwards;
}
@keyframes visitedWeightAnimation
{
    0%
    {
        background-color: rgba(0, 0, 66, .75);
    }

    50%
    {
        background-color: rgba(17, 104, 217, .75);
    }

    75%
    {
        transform: scale(1.2);

        background-color: rgba(0, 217, 159, .75);
    }

    100%
    {
        transform: scale(1);

        opacity: .75;
        background-image: url(images/visitedWeight.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

.node-path
{
    animation-name: shortestPathAnimation;
    animation-duration: 1.5s;
    animation-play-state: running;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-iteration-count: 1;
    animation-direction: alternate;

    animation-fill-mode: forwards;
}
@keyframes shortestPathAnimation
{
    0%
    {
        background-color: rgba(69, 240, 92, .842);
    }

    50%
    {
        background-color: rgba(18, 133, 241, .945);
    }

    75%
    {
        transform: scale(1.2);

        background-color: rgba(104, 163, 230, .75);
    }

    100%
    {
        transform: scale(1);

        background-color: rgba(255, 242, 0, .75);
    }
}

.node-path-weight
{
    animation-name: shortestPathAnimationWeight;
    animation-duration: 1.5s;
    animation-play-state: running;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-iteration-count: 1;
    animation-direction: alternate;

    animation-fill-mode: forwards;
}
@keyframes shortestPathAnimationWeight
{
    0%
    {
        background-color: rgba(240, 171, 69, .842);
    }

    50%
    {
        background-color: rgba(241, 238, 18, .945);
    }

    75%
    {
        transform: scale(1.2);

        background-color: rgba(104, 163, 230, .75);
    }

    100%
    {
        transform: scale(1);

        background-image: url(images/pathWeight.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
